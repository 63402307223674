<template>
    <div class="animated fadeIn">
        <CCard>
            <CCardHeader><font-awesome-icon icon="link"/> Document File API
                <!-- <CButton 
                    v-if="da_list_all.some(function(item) { return item.status == 'Archived'})"
                    size="sm"
                    :color="!show_archived ? 'success' : 'secondary'" 
                    class="float-right" 
                    @click="show_archived = !show_archived"
                >
                    <font-awesome-icon 
                    :icon="!show_archived ? 'eye' : 'eye-slash'"/> {{!show_archived ?  'Show' : 'Hide'}} Archived
                </CButton> -->
            </CCardHeader>
            
            <CCardBody>
                <CTabs>
                    <br>  
                    <CTab>
                        <template slot="title">
                            Active File API/s <CBadge color="success">( {{ [...new Set(da_list.map((item) => item.category))].length }} )</CBadge>
                        </template>
                        <CRow>
                            <CCol lg="12">
                                <CDataTable
                                    :items="da_list"
                                    :fields="da_list_fields"
                                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                    :items-per-page="10"
                                    border
                                    items-per-page-select
                                    sorter
                                    pagination
                                    :loading="this.table_loading"
                                >
                                <template slot="no-items-view" v-if="this.table_loading">
                                    <center>
                                        <CSpinner
                                            style="width:4rem;height:4rem;"
                                            color="info"
                                        />
                                    </center>
                                </template>
                                <template #method="{item}">
                                    <td>
                                        <CBadge :color="getColorStatus(item.method)">
                                            {{item.method}}
                                        </CBadge>
                                    </td>
                                </template>
                                <template #url="{item}">
                                    <td :title="item.url">                
                                        {{item.url.length > 100 ? item.url.substring(0, 100) + '...' :  item.url}}
                                    </td>
                                </template>
                                <template #action="{item, index}">
                                    <td>
                                        <router-link :to="{path: `/${$store.getters['getOrganizationName']}/drs/document-template-file-api/${item.id}`}">
                                            <CButton 

                                                color="info" 
                                                shape="pill" 
                                                size="sm"
                                            >
                                                <font-awesome-icon icon="cog"/> Manage
                                            </CButton>
                                        </router-link>
                                        
                                        <CButton 
                                            v-if="$store.getters['can']('delete-document-template-file-api')"
                                            @click="removeFileAPI(item, index)"
                                            color="danger" 
                                            shape="pill" 
                                            size="sm"
                                        >
                                            <font-awesome-icon icon="times"/> Remove
                                        </CButton>
                                    </td>
                                </template>
                                </CDataTable>
                            </CCol>
                        </CRow>
                    </CTab>
                    
                    <CTab disabled>
                        <template slot="title">
                            Inactive File API/s <CBadge color="danger">( 0 )</CBadge>
                        </template>
                        <CRow>
                            <CCol lg="12">
                            </CCol>
                        </CRow>
                    </CTab>
                </CTabs>
            </CCardBody>
        </CCard>
    </div>
</template>
<script>
export default {
    name: 'DocumentAPILists',
    data() {
        return {
            table_loading: true,
            
            // show_archived: false,

            da_list: [],
            da_list_all: []
        }
    },
    computed: {
        da_list_fields: function () {
            let custom_fields= [
                { key: 'name', label: 'Name'},
                { key: 'method' },
                { key: 'url' },
                // { key: 'status' },
            ]
            if(this.$store.getters.can('view-document-template-file-api')) {
                custom_fields.push({ key: 'action', sorter: false})
            }
            return custom_fields;
        }
    },
    created() {
        this.getDocumentFileApi();
        this.$emit('activeTab', 0);
    },
    methods: {
        getDocumentFileApi: function() {
            this.$Progress.start()
            axios.get('/drs/document-template-file-api/list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for (let i = 0; i < response.data.data.length; i++) {
                        let row = {
                                id:  response.data.data[i].id, 
                                name:  response.data.data[i].name, 
                                method:  response.data.data[i].method, 
                                url:  response.data.data[i].api,
                                // status: response.data.data[i].deleted_at ? 'Archived' : 'Active', 
                        }
                        // if(row.status == 'Active') {
                            this.da_list.push(row)
                        // }
                        // this.da_list_all.push(row)
                    }
                    this.table_loading = false;
                    this.$Progress.finish()
                }
            })
        },
        removeFileAPI: function (item, index) {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to remove the <b>${item.name}</b> File API.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/drs/document-template-file-api/delete/' + item.id, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            
                            this.$Progress.finish()
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${item.name}</b> Successfully deleted.`,
                            })
                            this.da_list.splice(index, 1)
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
            return;
        }

    }
}
</script>
